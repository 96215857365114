import { addClass, removeClass, hasClass, toggleClass } from "./helperFunctions";

import {timings} from "./audioTimings";

let audioButtonContainer = document.querySelector("div.AudioButton.mainAudioButton");
let audioButtons = document.querySelectorAll("svg.play-button");
let play = document.querySelector("#play");
let pause = document.querySelector("#pause");
let circle = document.querySelector(".playButton_Circle")

audioButtons.forEach(audioButton => {
    audioButton.addEventListener("click", function() {
        if ( hasClass(this, "playing") ) {
            pauseAudioButton(this);
            pauseAudio();
        } else {
            playAudioButton(this);
            initAudioPlayer(this);
        }
    })
}
);

export const pauseAudioButton = () => {
    let audioButtons = document.querySelectorAll("svg.play-button");
    audioButtons.forEach(audioButton => {
        removeClass(audioButton, "playing");
    })
}

export const playAudioButton = (audioButton) => {
    addClass(audioButton, "playing");
}

export const resetAudioButton = () => {
        let audioPlayer = document.querySelector("#AudioBiblePlayer");
        let audioButtons = document.querySelectorAll("svg.play-button");
        audioButtons.forEach(audioButton => {
            removeClass(audioButton, "playing");
        })
        audioPlayer.removeEventListener("timeupdate", audioProgress)

        document.querySelector('.playButton_Circle').style.strokeDashoffset = 0;
        document.querySelector('.playButton_ProgIndicator_Circle').style.strokeDashoffset = 1928;
}

export const initAudioPlayer = () => {
    if (window.location.pathname === '/' | window.location.pathname === '/landing/christmas/') {
        let AudioPlayer = document.querySelector("#AudioBiblePlayer");
        googleTagManager_AudioTracking();
        if (!hasClass(audioButtonContainer, "loaded")) {
            addClass(audioButtonContainer, "loading");
            AudioPlayer.load();
            AudioPlayer.addEventListener("canplay", event => {
                /* the audio is now playable; play it if permissions allow */
                addClass(audioButtonContainer, "loaded");
                removeClass(audioButtonContainer, "loading");
                playAudio();
            });
        } else {
            playAudio();
        }
        return;
    }
    var bookPath = window.location.pathname;
    var addressHash = window.location.hash;
    var urlChapter = addressHash.replace('#ch', "");
    urlChapter = ("0" + urlChapter).slice(-2);
    var bookPathParts = bookPath.match('\/(.*)\/(.*).html');
    var audioPath = `/${bookPathParts[1]}/Audio/${bookPathParts[2]}/${bookPathParts[2]}-${urlChapter}.mp3`;

    var audioURL = window.location.origin + audioPath;
 
    let AudioPlayer = document.querySelector("#AudioBiblePlayer");

    if (AudioPlayer) {
        if (AudioPlayer.src == audioURL) {
            playAudio();
        } else {
            document.querySelector("#AudioBiblePlayer").remove();
            loadAudio(audioPath);
        }
    } else {
        loadAudio(audioPath);
    }
}

export const loadAudio = (audioPath) => {
    var audio = new Audio(audioPath);
    audio.id = "AudioBiblePlayer";
    audioButtonContainer.appendChild(audio).id;
    addClass(audioButtonContainer, "loading");

    googleTagManager_AudioTracking();
    audio.addEventListener("canplaythrough", event => {
        /* the audio is now playable; play it if permissions allow */
        removeClass(audioButtonContainer, "loading");
    });
    playAudio();
}
export const playAudio = () => {
    let audioPlayer = document.querySelector("#AudioBiblePlayer");
    let playPromise = audioPlayer.play();
    
    if (playPromise !== undefined) {
        playPromise.then(function() {
            audioPlayer.addEventListener("timeupdate", audioProgress );
            audioPlayer.addEventListener("ended", function() {
                resetAudioButton();
            });
        }).catch(function(error) {
            console.log(error);
            let audioButtonContainer = document.querySelector("div.AudioButton.mainAudioButton");

            addClass(audioButtonContainer, "error");
            removeClass(audioButtonContainer, "loading");
            setTimeout(removeClass, 5000, audioButtonContainer, "error");
            pauseAudioButton();

        });
      }
}

export const pauseAudio = () => {
    let audioPlayer = document.querySelector("#AudioBiblePlayer");
    audioPlayer.pause();
}

export const audioProgress = () => {
    var audio = document.querySelector("#AudioBiblePlayer");
    // var circle = document.querySelector('.playButton_Circle');
    var progIndicator_Circle = document.querySelector('.playButton_ProgIndicator_Circle');
    addClass(progIndicator_Circle, "playing");
    var perctComplete = audio.currentTime / audio.duration;
    // let dashOffset = -perctComplete * 1928;
    let progOffset = 1928 - (perctComplete * 1928);
    // circle.style.strokeDashoffset = dashOffset;
    progIndicator_Circle.style.strokeDashoffset = progOffset;
    audioHighlight(audio.currentTime);

}

export const audioHighlight = (currentTime) => {
    let bookPath = window.location.pathname;
    let addressHash = window.location.hash.replace('#', "");

    if (bookPath == "/") {
        bookPath = "/TPV/TPV-44-JHN.html";
        addressHash = "ch1";
    }

    if (bookPath == "/landing/christmas/") {
        bookPath = "/TPV/TPV-43-LUK.html";
        addressHash = "christmas";
    }

    let timingList = timings[bookPath][addressHash];
    if(timingList) {
        timingList.forEach((timing) => {
            if (currentTime >= timing.start && currentTime <= timing.end) {
                let elements = document.querySelectorAll(`.${addressHash} .${timing.label}`);
                if (elements) {
                    elements.forEach((element) => {
                        addClass(element, 'audiohighlight');
                        element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    })
                } else { 
                    console.warn(`.${timing.label} element does not exist`)
                }
            } 

            if ( currentTime >= timing.end) {
                let elements = document.querySelectorAll(`.${addressHash} .${timing.label}`);
                if (elements) {
                    elements.forEach((element) => {
                        removeClass(element, 'audiohighlight');
                    })
                }
            }
        })
    }
}

var last_known_scroll_position = 0;

export const minimizeAudioButton = (scroll_pos) => {
    let classMinimizer = "audio_minimized";
    if (audioButtonContainer) {
        if (scroll_pos >= 150 ) {
            addClass(audioButtonContainer, classMinimizer);
        } else {
            removeClass(audioButtonContainer, classMinimizer);
        }
        last_known_scroll_position = scroll_pos;
    }
}

function googleTagManager_AudioTracking() {
    // This is gonna our percent buckets ( 25%-75% ) 
    var divisor = 10;
    // We're going to save our players status on this object. 
    var audios_status = {};
    // This is the funcion that is gonna handle the event sent by the player listeners 
    function eventHandler(e) {
        switch (e.type) {
            // This event type is sent everytime the player updated it's current time, 
            // We're using for the % of the audio played. 
        case 'timeupdate':
            // Let's set the save the current player's audio time in our status object 
            audios_status[e.target.id].current = Math.round(e.target.currentTime);
            // We just want to send the percent events once 
            var pct = Math.floor(100 * audios_status[e.target.id].current / e.target.duration);
            for (var j in audios_status[e.target.id]._progress_markers) {
                if (pct >= j && j > audios_status[e.target.id].greatest_marker) {
                    audios_status[e.target.id].greatest_marker = j;
                }
            }
            // current bucket hasn't been already sent to GA?, let's push it to GTM
            if (audios_status[e.target.id].greatest_marker && !audios_status[e.target.id]._progress_markers[audios_status[e.target.id].greatest_marker]) {
                audios_status[e.target.id]._progress_markers[audios_status[e.target.id].greatest_marker] = true;
                dataLayer.push({
                    'event': 'audio',
                    'eventCategory': 'HTML5 audio',
                    'eventAction': audios_status[e.target.id].greatest_marker + '%',
                    // We are using sanitizing the current audio src string, and getting just the audio name part
                    'eventLabel': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
                });
            }
            break;
            // This event is fired when user's click on the play button
        case 'play':
            dataLayer.push({
                'event': 'audio',
                'eventCategory': 'HTML5 audio',
                'eventAction': 'Played audio',
                'eventLabel': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
            });
            break;
            // This event is fied when user's click on the pause button
        case 'pause':
            dataLayer.push({
                'event': 'audio',
                'eventCategory': 'HTML5 audio',
                'eventAction': 'Paused audio',
                'eventLabel': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
                'eventValue': audios_status[e.target.id].current
            });
            break;
            // If the user ends playing the audio, an Finish audio will be pushed ( This equals to % played = 100 )  
        case 'ended':
            dataLayer.push({
                'event': 'audio',
                'eventCategory': 'HTML5 audio',
                'eventAction': '100%',
                'eventLabel': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
            });
            break;
        default:
            break;
        }
    }
    // We need to configure the listeners
    // Let's grab all the the "audio" objects on the current page     
    var audios = document.getElementsByTagName('audio');
    for (var i = 0; i < audios.length; i++) {
        // In order to have some id to reference in our status object, we are adding an id to the audio objects
        // that don't have an id attribute. 
        var audioTagId;
        if (!audios[i].getAttribute('id')) {
            // Generate a random alphanumeric string to use is as the id
            audioTagId = 'html5_audio_' + Math.random().toString(36).slice(2);
            audios[i].setAttribute('id', audioTagId);
        }// Current audio has alredy a id attribute, then let's use it <img draggable="false" class="emoji" alt="?" src="https://s.w.org/images/core/emoji/2/svg/1f642.svg">
        else {
            audioTagId = audios[i].getAttribute('id');
        }
        // audio Status Object declaration  
        audios_status[audioTagId] = {};
        // We'll save the highest percent mark played by the user in the current audio.
        audios_status[audioTagId].greatest_marker = 0;
        // Let's set the progress markers, so we can know afterwards which ones have been already sent.
        audios_status[audioTagId]._progress_markers = {};
        for (var j = 0; j < 100; j++) {
            audios_status[audioTagId].progress_point = divisor * Math.floor(j / divisor);
            audios_status[audioTagId]._progress_markers[audios_status[audioTagId].progress_point] = false;
        }
        // On page DOM, all players currentTime is 0 
        audios_status[audioTagId].current = 0;
        // Now we're setting the event listeners. 
        audios[i].addEventListener("play", eventHandler, false);
        audios[i].addEventListener("pause", eventHandler, false);
        audios[i].addEventListener("ended", eventHandler, false);
        audios[i].addEventListener("timeupdate", eventHandler, false);
        audios[i].addEventListener("ended", eventHandler, false);
    }
}