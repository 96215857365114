import { onboard } from "/modules/onboarding";
import {
  addClass,
  removeClass,
  toggleClass,
  hasClass
} from "./helperFunctions";

export function bookSelector() {
  // console.log("book Selector");
  onboard({ book: true });
 // ga("send", "event", "BookSelector", "Book Selector Toggle Clicked");
  //removeClass(document.querySelector("#Chapters_list"), "show");
  toggleClass(document.querySelector("#Books_list"), "show");

  scrollToCurrentBook();

  if (hasClass(document.querySelector("#Books_list"), "show")) {
    addClass(document.querySelector("#bookListToggle .chevron"), "up");
  } else {
    removeClass(document.querySelector("#bookListToggle .chevron"), "up");
  }
}

function scrollToCurrentBook() {
  var screen = document.querySelector("html");
  var scrollLoc = document.querySelector(bookCode).offsetTop;
  var offset = document.querySelector(".bookHeaderRow").offsetHeight;
  var offset = offset * 1.5; //this makes a bit of the previous book show so that it is clear that the list is scrollable.
  // console.log(bookCode);
  // console.log(offset);
  screen.scrollTop = scrollLoc - offset;
}

export function toggleChapters(event) {
  event.preventDefault();

  //ga("send", "event", "BookSelector", "Book Name Clicked");
  // console.log(this.parentNode);
  if (hasClass(this.parentNode, "active")) {
    closeChapters(this.parentNode);
  } else {
    var openedBooks = document.querySelector("li.active");
    if (openedBooks) {
      closeChapters(openedBooks);
    }
    showChapters(this.parentNode);
  }
}

function closeChapters(openBook) {
  removeClass(openBook, "active");
  removeClass(openBook.querySelector(".chevron"), "down");
  addClass(openBook.querySelector(".chevron"), "up");
}

function showChapters(BookToShow) {
  addClass(BookToShow, "active");
  removeClass(BookToShow.querySelector(".chevron"), "up");
  addClass(BookToShow.querySelector(".chevron"), "down");
}
