export function ServiceWorker() {
  var swURL = "/sw.js";

  navigator.serviceWorker &&
    navigator.serviceWorker.register(swURL).then(function(registration) {
      console.log("Excellent! registered with scope: ", registration.scope);
    });

  var btnInstall = document.querySelector("#downloadLink");

  //Event Listener for Web App Install Prompt
  let installPromptEvent;

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    installPromptEvent = e;
    // Update UI notify the user they can install the PWA
    
    btnInstall.addEventListener("click", function() {
      e.preventDefault();
      // Show the modal add to home screen dialog
      installPromptEvent.prompt();
      //ga("send", "event", "Install", "Clicked_DLButton", "Web App Banner");
      // Wait for the user to respond to the prompt
      installPromptEvent.userChoice.then(function(choice) {
        if (choice.outcome === "accepted") {
          // console.log("User accepted the A2HS prompt");
          //ga("send", "event", "Install", "accept", "Web App Banner");
        } else {
          // console.log("User dismissed the A2HS prompt");
          //ga("send", "event", "Install", "decline", "Web App Banner");
        }
        // Clear the saved prompt since it can't be used again
        installPromptEvent = null;
      });
    });
  });

}
