import { toggleClass } from "./helperFunctions";
/***
 *
 * Toggle FootNotes
 *
 ***/
function toggleNote(marker) {
  // console.log(marker.parentNode);
  toggleClass(marker.parentNode, "open");
}

window.toggleNote = toggleNote;
