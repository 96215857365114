import { addClass, removeClass } from "./helperFunctions";
import { requestAnimationFramePolyfill } from "/modules/requestAnimationFramePolyfill";

// requestAnimationFramePolyfill;
var last_known_scroll_position = 0;

export function showNextPrevButtons(scroll_pos) {
  if (document.querySelector('.landingPageContainer')) {
    return null;
  }
  var nextLinks = document.querySelector(".nextLinks");
  var chapterDiv = document.querySelector(".show");
  var chapterHeight = chapterDiv.offsetHeight;
  var audioButtonHeight = document.querySelector(".AudioButton.mainAudioButton.audio_minimized") ? document.querySelector(".AudioButton.mainAudioButton.audio_minimized").offsetHeight : 0;
  var chapterTotalHeight =
    chapterHeight +
    chapterDiv.offsetTop +
    document.querySelector(".copyright").offsetHeight +
    document.querySelector(".didYouUnderstand").offsetHeight;

  var classVisible = "visible";
  var classBottom = "bottom";
  if (scroll_pos === 0 || scroll_pos < last_known_scroll_position) {
    addClass(nextLinks, classVisible);
  } else {
    removeClass(nextLinks, classVisible);
  }
  if (window.innerHeight + window.pageYOffset >= chapterTotalHeight) {
    // console.log("we are at the bottom or are we?");
    //if we are scrolled to the bottom of the chapter add the bottom class to nextLinks
    addClass(nextLinks, classVisible);
    addClass(nextLinks, classBottom);
    //Audio Player changes for bottom of the page. 
    var audioButton = document.querySelector(".AudioButton.mainAudioButton ");

    if (audioButton) {
      addClass(audioButton, "bottom");
    }
    //this positions the next button properly after the chapter but before the More info box below
    nextLinks.querySelector(".next").style.top =
      chapterHeight + chapterDiv.offsetTop + 32 + "px";
  } else {
    //if not at the bottom remove the bottom class from nextLinks
    removeClass(nextLinks, classBottom);

    var audioButton = document.querySelector(".AudioButton.mainAudioButton ");

    if (audioButton) {
      removeClass(audioButton, "bottom");
    }
    //remove the top position style because we aren't at the bottom anymore
    nextLinks.querySelector(".next").style.top = null;
  }
  last_known_scroll_position = scroll_pos;
}
