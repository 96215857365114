import { addClass, toggleClass, removeClass } from "./helperFunctions";

export function landingPageSwitcher() { 
    // // let landingPageNumber = localStorage.getItem("landingPage");

    // // if (landingPageNumber) {
    // //     if (landingPageNumber !== "1") {
    // //         removeClass(document.querySelector('#landing1'), 'show');
    // //     }
    //     let landingPageNumber = getRandomInt(1, numberOflandings, landingPageNumber);
    //     addClass(document.querySelector(`#landing${landingPageNumber}`), "show");        
    // //}
    // // var nextLandingPageNumber = getRandomInt(1, numberOflandings, landingPageNumber);
    // // localStorage.setItem("landingPage", parseFloat(nextLandingPageNumber));
}

export function getRandomInt(min, max, current) {
    // var randNum = Math.floor(Math.random() * (max - min + 1)) + min;
    // if (randNum === current) {
    //     console.log("this is a repeat");
    //     return getRandomInt(min, max, current);
    // } else {
    //     return randNum;
    // }
}