import { getDOY } from "/modules/getDOY";

// getDOY;

export function getVotD() {
  var VotDSection = document.querySelector(".verseOfTheDay");
  fetch("../VotD.json")
    .then(function(response) {
      return response.json();
    })
    .then(function(VotD) {
      var DOY = new Date().getDOY();
      var verseText = VotD[DOY].faText;
      var bookName = VotD[DOY].faBookName;
      var chapterNumber = VotD[DOY].chapterNumber;
      var verseNumber = VotD[DOY].verseNumbers;
      VotDSection.querySelector(".mdc-drawer__subtitle").innerText =
        bookName + " " + verseNumber + ":" + chapterNumber;
      VotDSection.querySelector(".mdc-drawer__p").innerText = verseText;
    });
}
