import { toggleClass, removeClass, addClass } from "./helperFunctions";

const algoliasearch = require("algoliasearch/lite");

let algolia_Client = algoliasearch(
  "D23RIJWH2P",
  "5a6a7483d4d08ba7d283491964f3a160"
);

let algolia_Index = algolia_Client.initIndex("Enjil.me_TPV");

var searchForm = document.querySelector(".search-form");
var preDefined_searches = document.querySelector(".preDefined_searches");

if (searchForm) {
  searchForm.addEventListener("submit", function(e) {
    e.preventDefault(); //stop form from submitting
    var searchTerm = document.querySelector(".search-input").value;

    initialSearch(searchTerm);
  });
}

if (preDefined_searches) {
  preDefined_searches.addEventListener("click", function(e) {
    initialSearch(e.target.dataset.searchterm);
  })
}
export function initialSearch(searchTerm, filters) {
  var searchResults = document.querySelector(".searchResults");
  var preDefined_searches = document.querySelector('.preDefined_searches');
  toggleClass(preDefined_searches, 'hide');
  searchResults.insertBefore(SVGspinnerTemplate(), undefined);

  searchAlgolia(searchTerm, undefined, filters).then(res => {
    searchResults.innerHTML = "";
    console.log(res);

    if (res.nbHits !== 0){
      res.hits.forEach(function(hit) {
        searchResults.insertBefore(searchTemplate(hit), undefined);
      });

      searchResults.insertAdjacentElement(
        "beforeend",
        loadMoreButton(searchTerm, 1, filters)
      );
      var LoadMoreButton = document.querySelector("#loadMoreButton");

      LoadMoreButton.addEventListener("click", function(e) {
        moreSearch(
          LoadMoreButton.dataset.searchterm,
          LoadMoreButton.dataset.pagenum,
          LoadMoreButton.dataset.filter
        );
      });
    } else {
      removeClass(preDefined_searches, 'hide');
      searchResults.insertBefore(noResultsTemplate(res.query), undefined);
    }
  });
}

export function moreSearch(searchTerm, pageNum, filters) {
  var searchResults = document.querySelector(".searchResults");
  searchResults.insertBefore(SVGspinnerTemplate(), undefined);

  searchAlgolia(searchTerm, pageNum, filters).then(res => {
    //removed the loading Spinner
    searchResults.removeChild(searchResults.querySelector("#loadingSpinner"));
    //removed the old Load more button
    searchResults.removeChild(searchResults.querySelector("#loadMoreButton"));
    loadMoreButton;
    res.hits.forEach(function(hit) {
      searchResults.insertBefore(searchTemplate(hit), undefined);
    });

    searchResults.insertAdjacentElement(
      "beforeend",
      loadMoreButton(searchTerm, pageNum + 1, filters)
    );
  });
}

export function searchAlgolia(searchTerm, pageNum = 0, filters) {
  return new Promise((resolve, reject) => {
    var searchQuery = {};

    if (filters) {
      searchQuery.filters = filters;
    }

    if (pageNum) {
      searchQuery.page = parseInt(pageNum);
    }

    algolia_Index.search(searchTerm, searchQuery).then(res => {
      console.log(res.hits);
      return resolve(res);
    });
  });
}

function searchTemplate(searchResult) {
  var searchLine = `<a href="${searchResult.link}.${searchResult.verse}">
    <li class="searchResult" id="${searchResult.book}${searchResult.chapter}:${searchResult.verse}">
        <h3 class="reference">${searchResult.book} ${searchResult.chapter}:${searchResult.verse}</h3>
            <p class="verse">${searchResult._highlightResult.content.value}</p>
    </li>
    </a>`;

  return createElementFromHTML(searchLine);
}

function noResultsTemplate(queryString) {
  var noResults = `<h2>هیچ نتیجه ای برای ${queryString} یافت نشد</h2>`;
  
  return createElementFromHTML(noResults);
}

function SVGspinnerTemplate() {
  var spinner = `<svg version="1.1" id="loadingSpinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
   <rect x="0" y="0" width="4" height="20" fill="#333">
     <animate attributeName="opacity" attributeType="XML"
       values="1; .2; 1" 
       begin="0s" dur="0.6s" repeatCount="indefinite" />
   </rect>
   <rect x="7" y="0" width="4" height="20" fill="#333">
     <animate attributeName="opacity" attributeType="XML"
       values="1; .2; 1" 
       begin="0.2s" dur="0.6s" repeatCount="indefinite" />
   </rect>
   <rect x="14" y="0" width="4" height="20" fill="#333">
     <animate attributeName="opacity" attributeType="XML"
       values="1; .2; 1" 
       begin="0.4s" dur="0.6s" repeatCount="indefinite" />
   </rect>
 </svg>`;

  return createElementFromHTML(spinner);
}

function loadMoreButton(searchTerm, pageNum, filters) {
  if (!filters) {
    filters = "";
  }

  var Morebtn = `<button id="loadMoreButton" class="btn" data-searchTerm="${searchTerm}" data-pageNum="${pageNum}" data-filter="${filters}">بیشتر</button>`;

  return createElementFromHTML(Morebtn);
}
function createElementFromHTML(htmlString) {
  var div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

function updateURL(searchTerm) {
  let params = (new URL(document.location)).searchParams;
 console.log(params);
  //Query string is now: 'foo=1&bar=2&foo=4'
}